var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"checkin-form"},[_c('span',{staticClass:"checkin-form__date text-gray-600 dark:text-gray-300"},[_vm._v(" "+_vm._s(_vm.todayDateString)+" ")]),_c('div',{staticClass:"checkin-form__input-segment"},[_c('InputHeader',{attrs:{"label-for":"checkinHour_checkinMinute","title":"Jam Presensi"}}),_c('div',{staticClass:"checkin-form__hour-min"},[_c('p',{staticClass:"text-gray-600 dark:text-gray-300"},[_vm._v("Jam")]),_c('p',{staticClass:"text-gray-600 dark:text-gray-300"},[_vm._v("Menit")]),_c('Input',{attrs:{"disabled":"","name":"checkinHour","value":_vm.checkinHour}}),_c('Input',{attrs:{"disabled":"","name":"checkinMinute","value":_vm.checkinMinute}})],1)],1),_c('div',{staticClass:"checkin-form__input-segment"},[_c('RadioButtonGroup',{staticClass:"mb-2",attrs:{"name":"location","title":"Jenis Presensi","rules":"required","disabled":false,"block":true,"options":_vm.locationOptions,"custom-messages":{
          required: 'Jenis presensi harus diisi'
        }},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('span',{staticClass:"italic text-gray-500"},[_vm._v(" Pilih salah satu ")])]},proxy:true}],null,true),model:{value:(_vm.payload.location),callback:function ($$v) {_vm.$set(_vm.payload, "location", $$v)},expression:"payload.location"}})],1),_c('div',{staticClass:"checkin-form__input-segment"},[_c('MoodInput',{staticClass:"mb-12",attrs:{"name":"mood"},model:{value:(_vm.payload.mood),callback:function ($$v) {_vm.$set(_vm.payload, "mood", $$v)},expression:"payload.mood"}})],1),(_vm.checkinHour>=8 || (_vm.checkinHour>=7 && _vm.checkinMinute>=30))?_c('div',{staticClass:"checkin-form__input-segment"},[_c('InputTextarea',{attrs:{"name":"note","rows":"5","title":"Alasan dan Solusi Keterlambatan Presensi","rules":"required|min:25","placeholder":_vm.noteLatePlaceholder,"custom-messages":{
          required: 'Alasan dan Solusi Keterlambatan Presensi Harus Diisi',
          min: 'Minimal 25 Karakter'
        }},model:{value:(_vm.payload.note),callback:function ($$v) {_vm.$set(_vm.payload, "note", $$v)},expression:"payload.note"}})],1):_c('div',{staticClass:"checkin-form__input-segment"},[_c('InputTextarea',{attrs:{"name":"note","rows":"5","title":"Catatan Agenda","required":false,"placeholder":_vm.notePlaceholder},model:{value:(_vm.payload.note),callback:function ($$v) {_vm.$set(_vm.payload, "note", $$v)},expression:"payload.note"}})],1)]),(_vm.checkinHour>=6 || (_vm.checkinHour>=5 && _vm.checkinMinute>=30))?_c('button',{staticClass:"checkin-form__btn-submit",on:{"click":function($event){return handleSubmit(_vm.beforeSubmit)}}},[_vm._v(" Kirim ")]):_c('div',{staticClass:"px-4 py-2 mt-12 rounded-md border border-solid border-orange-500 bg-orange-100 text-gray-700 text-center"},[_c('strong',[_c('span',{staticClass:"text-orange-700"},[_vm._v("Checkin bisa dilakukan setelah 05:30")])])]),_c('Dialog',{attrs:{"show":_vm.showSubmissionDialog}},[(_vm.showSubmissionDialog)?_c('Submission',_vm._b({on:{"success":_vm.onSubmissionSuccess,"error":_vm.onSubmissionError,"cancel":_vm.onCloseConfirmationDialog,"close":_vm.onCloseConfirmationDialog}},'Submission',{ payload: _vm.payload },false)):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }